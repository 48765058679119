import { AttributeTypes, TemplateAttribute } from '@models/productTemplate';
import { capitalizeEveryFirstLetter } from '@utils/textTransform';
import { useState } from 'react';
import { AnyAttributeValue } from '@models/product';
import { AssetCache } from '../../../hooks/useAssets';
import {
  AttributeValueError,
  isValidAttributeValue,
} from '@utils/productTemplate';
import { Asset } from '../Asset';

import { ReactComponent as RCHorseIcon } from '@assets/icons/RCHorse.svg';
import { ReactComponent as HTMLIcon } from '@assets/icons/HTMLIcon.svg';
import { UrlIcon } from '@components/Icons/UrlIcon';

import './style.scss';

type AttributeView<T extends AnyAttributeValue> = (
  value: T | undefined,
  assetCache: AssetCache,
) => React.ReactNode;

export const getRichContentIcon = (rc: string) => {
  if (rc.startsWith('data:text/html,')) return <HTMLIcon />;
  else if (rc.startsWith('data:text/uri-list,')) return <UrlIcon />;
  else return <RCHorseIcon />;
};

const fieldInputs = {
  text: ((value) => (
    <p
      className="text-field-view"
      dangerouslySetInnerHTML={{ __html: value || '' }}
    />
  )) satisfies AttributeView<string>,
  html: ((value) => (
    <p
      className="text-field-view"
      dangerouslySetInnerHTML={{ __html: value || '' }}
    />
  )) satisfies AttributeView<string>,
  richContent: ((richContent) => (
    <div className="rich-content-list">
      {richContent?.map((rc) => {
        return (
          <div className="rich-content-item" key={rc}>
            {getRichContentIcon(rc)}
          </div>
        );
      })}
    </div>
  )) satisfies AttributeView<string[]>,
  assets: ((assetIds, assetCache) => {
    const [selectedIndex, setSelectedIndex] = useState(0);

    return (
      <div className="gallery">
        <Asset
          assetId={assetIds?.at(selectedIndex)}
          assetCache={assetCache}
          className="hero"
        />
        {assetIds && assetIds.length > 1 && (
          <div className="thumbs">
            {assetIds.map((assetId, i) => (
              <Asset
                assetId={assetId}
                assetCache={assetCache}
                aria-selected={i === selectedIndex}
                onClick={() => setSelectedIndex(i)}
                key={i}
              />
            ))}
          </div>
        )}
      </div>
    );
  }) satisfies AttributeView<string[]>,
} satisfies {
  [type in AttributeTypes]: AttributeView<string> | AttributeView<string[]>;
};

/** An read-only view for a version of a product attribute */
const AttributeView = ({
  attribute,
  value,
  assetCache,
}: {
  attribute: TemplateAttribute<AttributeTypes>;
  value: AnyAttributeValue | undefined;
  assetCache: AssetCache;
}) => {
  if (value !== undefined && !isValidAttributeValue(attribute, value))
    throw new AttributeValueError(attribute, value);

  return (
    <div className="attribute-view">
      <h5>{capitalizeEveryFirstLetter(attribute.displayName)}</h5>
      {fieldInputs[attribute.type](value, assetCache)}
    </div>
  );
};

export default AttributeView;
