import { FunctionComponent } from 'react';

import './style.scss';

interface ProgressBarProps {
  value: number;
}

const ProgressBar: FunctionComponent<ProgressBarProps> = ({ value }) => {
  return (
    <div className="ProgressBar__progressBar">
      <div
        className="ProgressBar__progressBar--percentage"
        // Using inline styles as it is a dynamic value
        style={{ width: `${Math.round(value)}%` }}
      />
    </div>
  );
};

export default ProgressBar;
